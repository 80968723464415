import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { buildConfigurationProjectUserPermissions } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';

export const loadUserPermissions = (
    projectId: string,
    userId: string,
    activityId = '',
): Observable<any> => {
    const obs = sendRetrieveRequest(
        buildConfigurationProjectUserPermissions(projectId, userId, activityId),
    );
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const items = convertToCamel<any[]>(response.UserPermissions);
            return observableOf({
                items,
            });
        }),
    );
};
